<template>
<div class="w-full flex my-1 px-4" :class="{'justify-start flex-row-reverse': right, 'justify-start': left, 'mb-4': time}">
    <img :src="image" v-if="image" class="w-10 h-10 rounded-full object-center object-cover">
    <div class="">
        <div style="max-width: 300px !important; min-width: 100px;" class="py-2 px-5 rounded-3xl prose-sm select-text" :class="{'bg-white shadow': right, 'bg-orange-tint': left,  'rounded-br-xl': right && time, 'rounded-bl-xl': left && time}">
            <slot></slot>
        </div>
        <div class="w-full font-extralight text-xs" :class="{'text-right': right, 'text-left': left}">{{ time }}</div>
    </div>
    </div>
</template>

<script>
import { time_from_date } from "../helpers/parser"
    export default {
        props: {
            right: {
                type: Boolean,
                default: false,
            },
            left: {
                type: Boolean,
                default: false,
            },
            timestamp: {
                type: Number,
                default: null,
            },
            image: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                time: this.timestamp? time_from_date(this.timestamp): null,
            }
        },
    }
</script>