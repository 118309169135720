import { Configuration, OpenAIApi } from "openai";
import { TutoruuAPI } from "./helpers/API/Core";

const configuration = new Configuration({
    apiKey: process.env.VUE_APP_OPENAI_API_KEY,
});

const openai = new OpenAIApi(configuration);

export async function getCompletion(messages) {
  return (
    await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      messages,
      temperature: 0,
      max_tokens: 400,
      frequency_penalty: 0.5,
      presence_penalty: 0.2,
    })
  ).data.choices[0].message.content;
}

export class Dialogue {
  constructor(character, username, bot_name = "Ruu") {
    this.username = username;
    this.bot_name = bot_name;
    this.prompt = [this.createSeedPrompt(character)];
    this.response = "";
    this.messages = [];
  }

  async introduce() {
    TutoruuAPI.post("user/ruu/" + this.username)
    return await this.send("Introduce yourself!");
  }

  createSeedPrompt(character) {
    return { 
      "role": "system", 
      "content": `This is a conversation with ${this.username} and ${this.bot_name}. ${this.username} is a friendly person. ${this.bot_name} is a ${character}. \n\n${this.username}: `
    };
  }

  addMessage(text, person = true) {
    const message = { text, person, timestamp: Date.now() };
    this.messages.push(message);
    TutoruuAPI.patch("user/ruu/" + this.username + "/sendMessage", { message });
    this.prompt.push({ "role": person ? "user" : "assistant", "content": text });
    return (this.prompt);
  }

  async respond() {
    this.response = await getCompletion(this.prompt);
    this.addMessage(this.response, false);
    return this.response;
  }

  async send(message) {
    this.addMessage(message);
    return await this.respond();
  }
}